*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #ddd;
}

/* Handle */
*::-webkit-scrollbar-thumb:hover {
  border-radius: 5px;
  background-color: #bbb;
}

.manage-age-wrapper .chakra-radio .chakra-radio__label {
  font-size: 14px;
}
