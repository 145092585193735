.svgIcon:hover svg path,
.activeItem.svgIcon svg path {
  stroke: white;
}
.profileIcon:hover svg path,
.activeItem.profileIcon svg path {
  fill: white;
}
.svgIcon:hover svg circle,
.activeItem.svgIcon svg circle {
  stroke: white;
}
